import React from "react";
// import { Container } from "react-bootstrap"
// import {PDFObject} from "react-pdfobject"
export default function TermsAndCondition() {
 
  return (<div className="terms">
    {/* <h2>{"Terms and Conditions"}</h2> */}
    <div>
      <a target="_blank" className="text-underline" href="https://vost.ro/vgtcs">
        {"Click here for the Terms and Conditions"}
      </a>
    </div>
  </div>);

}