import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import Modal from "../bootstrap/modal";
import TermsAndCondition from "./terms-and-conditions";

export default function TermModal(props) {
  const { onClose } = props;

  return (
    <Modal
      title={"Privacy Policy"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto">
            <Button variant="vw-button btn btn-blue" type="button" onClick={() => onClose()}>
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid>
        <Row>
          <Col>
            <TermsAndCondition/>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
